
    import { defineComponent, reactive, ref, toRefs, onMounted, nextTick } from 'vue';
    import moment from "moment";
    import { ColumnProps } from 'ant-design-vue/es/table/interface';
    import Pagination from "@/components/Pagination.vue";
    import useFetch from "@/hooks/useFetch";
    import useTableHeight from "@/hooks/useTableHeight";
    import {
        getSubscribedBu,
        getReleaseBatchList,
        sendReleaseBatchList,
        sendReleaseEmail,
        discardReleaseBatch,
        pdfPreview,
        releaseBatchStatus
    } from "@/API/approve";
    import { QueryParams, ReleaseBatch } from './types';
    import { message, Modal } from "ant-design-vue";
    import {
        BuItem,
    } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
    import blobDownload from "@/utils/blobDownload";

    type Key = ColumnProps['key'];

    const columns = [
        {
            title: 'Batch Release Name',
            dataIndex: 'releaseBatchName',
            key: 'batchReleaseName',
            slots: { customRender: 'batchReleaseName' },
            width: 230,
            align: 'left',
            ellipsis: true
        },
        {
            title: 'Package Status',
            dataIndex: 'status',
            key: 'status',
            width: 130
        },
        {
            title: 'MBAFC Approval Status',
            dataIndex: 'mbafcapprovalStatus',
            key: 'mbafcapprovalStatus',
            width: 170
        },
        {
            title: 'MBLC Approval Status',
            dataIndex: 'mblcapprovalStatus',
            key: 'mblcapprovalStatus',
            width: 160
        },
        {
            title: 'Release Time',
            dataIndex: 'releaseAt',
            key: 'releaseAt',
            slots: { customRender: 'releaseAt' },
            width: 160
        },
        {
            title: 'Release To',
            dataIndex: 'releaseTo',
            key: 'releaseTo',
            slots: { customRender: 'releaseTo' },
            width: 160
        },
        {
            title: 'Release By',
            dataIndex: 'createdBy',
            key: 'releaseBy',
            width: 100
        }
    ];


    export default defineComponent({
        name: "ProgramReleasePage",
        components: { Pagination },
        setup() {
            const { tableHeight } = useTableHeight();
            const isOpen = ref(false)
            const handleCollapse = () => {
                isOpen.value = !isOpen.value
                nextTick(() => {
                    const tablePosition = useTableHeight(true, '.pagination-container', true);
                    tableHeight.value = tablePosition.tableHeight.value
                })
            }

            //query Params
            const queryParams = reactive<QueryParams>({
                bu: '',
                releasePeriod: '',
                releaseBatchName: '',
                status: '',
                mbafcStatus: '',
                mbclStatus: '',
                myBatch: false
            });

            const { data, pagination, refreshFetch } = useFetch<ReleaseBatch[]>(queryParams, [], getReleaseBatchList, true);

            //查询bu options
            const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu);

            //mbfafc mbcl status
            const faStatusOptions = ref<{ name: string; value: string }[]>([
                { name: 'Not Uploaded', value: 'NotUploaded' },
                { name: 'Uploaded', value: 'Uploaded' }
            ]);

            //release status
            const { data: releaseStatusOptions } = useFetch<ReleaseBatch[]>({}, [], releaseBatchStatus);
            

            const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: ReleaseBatch[]; onChange: Function }>({
                onChange: (selectedRowKeys: Key[], selectedRows: ReleaseBatch[]) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                    rowSelection.selectedRowData = selectedRows;
                },
                selectedRowKeys: [],
                selectedRowData: []
            })

            const validatePackageStatus = (status: string, messageText: string): Promise<any> => {
                return new Promise<void>((resolve, reject) => {
                    if (rowSelection.selectedRowData.length == 0) {
                        message.error('Please select programs');
                        return reject();
                    }
                    if (!rowSelection.selectedRowData.every((item) => item.status == status)) {
                        message.error(messageText);
                        return reject();
                    }
                    return resolve();
                })
            }

            //分页事件处理
            const handlerPaginationChange = (page: number): void => {
                pagination.page = page;
                refreshFetch();
                rowSelection.selectedRowData = [];
                rowSelection.selectedRowKeys = [];
            }

            const handlerShowSizeChange = (current: number, pageSize: number): void => {
                pagination.pageSize = pageSize;
                Object.assign(pagination, { page: 1, pageSize: pageSize });
                refreshFetch();
                rowSelection.selectedRowData = [];
                rowSelection.selectedRowKeys = [];
            }

            const handlerSendClick = (): void => {
                validatePackageStatus('Saved', '请选择状态为Saved状态的Release batch').then(data => {
                    sendReleaseBatchList(rowSelection.selectedRowData.map(item => ({ id: item.id }))).then(res => {
                        // message.success('send successfully');
                        const params = {
                            batchId: rowSelection.selectedRowData.map(item => ( item.id )).join()
                        } 
                        sendReleaseEmail(params).then(() => {
                            message.success('send successfully');
                        })
                        rowSelection.selectedRowData = [];
                        rowSelection.selectedRowKeys = [];
                        refreshFetch(1);
                    })
                })
            }

            const handlerDisCardClick = (): void => {
                validatePackageStatus('Saved', '请选择状态为Saved状态的Release batch').then(data => {
                    Modal.confirm({
                        title: '提示',
                        content: 'Discard Package ?',
                        onOk() {
                            return new Promise<void>((resolve, reject) => {
                                discardReleaseBatch(rowSelection.selectedRowData.map(item => ({ id: item.id }))).then(res => {
                                    message.success('Discard successfully');
                                    rowSelection.selectedRowData = [];
                                    rowSelection.selectedRowKeys = [];
                                    refreshFetch(1);
                                    resolve();
                                }).catch(e => {
                                    reject();
                                })
                            })
                        },
                        onCancel() {
                            console.log('Cancel');
                        },
                    });
                })
            }

            const handlerPdfPreviewClick = (): void => {
                if (rowSelection.selectedRowData.length == 0) {
                    message.error('请选择release batch');
                    return;
                }
                if (rowSelection.selectedRowData.length > 1){
                    message.error('请选择单个release batch');
                    return;
                }
                pdfPreview(rowSelection.selectedRowData[0].id).then(data => {
                    const href = window.URL.createObjectURL(data);
                    window.open(href);
                    setTimeout(() => {
                        window.URL.revokeObjectURL(href);
                    }, 1500)
                })
            }

            const handleWordingDownload = () => {
                if (rowSelection.selectedRowData.length === 0) {
                    message.error('请选择release batch');
                    return;
                }
                if (rowSelection.selectedRowData.length > 1){
                    message.error('请选择单个release batch');
                    return;
                }
                const params = {
                    batchId: rowSelection.selectedRowData[0].id,
                }
                blobDownload({url: '/pgapi/program/wording/download_release_batch', params: params})
            }

            const resetTableSelectData = (): void => {
                rowSelection.selectedRowKeys = [];
                rowSelection.selectedRowData = [];
            }

            const handlerResetClick = (): void => {
                Object.assign(queryParams, {
                    bu: '',
                    releasePeriod: '',
                    releaseBatchName: '',
                    status: '',
                    mbafcStatus: '',
                    mbclStatus: '',
                    myBatch: false
                })
                resetTableSelectData();
                refreshFetch(1);
            }

            const handlerSearchClick = (): void => {
                resetTableSelectData();
                refreshFetch(1);
            }

            onMounted(() => {
                console.log('***');
            })

            return {
                //query Params
                ...toRefs(queryParams),

                //table
                columns,
                data,
                rowSelection,
                tableHeight,
                pagination,

                //事件处理
                handlerPaginationChange,
                handlerShowSizeChange,
                handlerSendClick,
                handlerDisCardClick,
                handlerPdfPreviewClick,
                handlerResetClick,
                handlerSearchClick,

                //搜索的options
                buOptions,
                releaseStatusOptions,
                faStatusOptions,

                // moment
                moment,
                isOpen,
                handleCollapse,
                handleWordingDownload
            }
        }
    })
